import React, { useState, useEffect } from "react";
import PasswordMask from "react-password-mask";
import { gql, useQuery, useMutation } from "@apollo/client";
import { graphql } from "gatsby";
import Trans, { t } from "../../components/Translate";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../../layouts/default";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import FormResponse from "../../components/FormResponse";
import LoadingSpinner from "../../components/LoadingSpinner";

const SEND_PASSWORD_RESET_LINK = gql`
  mutation SendPasswordResetLink($emailAddress: String!) {
    sendPasswordResetLink(data: { emailAddress: $emailAddress }) {
      success
    }
  }
`;
const CHECK_PASSWORD_TOKEN = gql`
  mutation CheckPasswordResetToken($token: String!, $userId: Int!) {
    checkPasswordResetToken(data: { token: $token, userId: $userId }) {
      isValid
    }
  }
`;

const CONFIRM_NEW_PASSWORD = gql`
  mutation UpdateUserPasswordWithResetToken(
    $token: String!
    $userId: Int!
    $newPassword: String!
  ) {
    updateUserPasswordWithResetToken(
      data: { token: $token, userId: $userId, newPassword: $newPassword }
    ) {
      user {
        id
      }
    }
  }
`;

const getSuccessRedirectUrl = (location) => {
  // I had issues with this function. Tried to prevent redirecting back to registration if logging in from there and had issues. Now just sending logged in users to shifts page.
  // const prevPath = location?.state?.prevPath || null;
  // const referrer = prevPath ? `${window.location.origin}${prevPath}` : "";

  // const isACReferrer = referrer.match(/annesconcierge/i);
  // const isLoginPage = window.location.href.match(
  //   /^(.*annesconcierge)(.*login)/s
  // );

  // if (isLoginPage && isACReferrer) {
  //   // if (!referrer.match("/login")) {
  //   //   console.log("sendto1", referrer);
  //   //   return referrer;
  //   // }
  //   return referrer;
  // } else if (!isLoginPage) {
  //   return window.location;
  // }

  // return window.location.origin;
  return "/shifts";
};

const Login = ({ location }) => {
  const [resetPassword, { error: mutationError, loading }] = useMutation(
    SEND_PASSWORD_RESET_LINK
  );
  const [checkResetToken, { loading: loadingTokenVerification }] = useMutation(
    CHECK_PASSWORD_TOKEN
  );
  const [
    confirmPassword,
    // { error: mutationError, loading }
  ] = useMutation(CONFIRM_NEW_PASSWORD);
  const [showForm, setShowForm] = useState(false);
  const [resetTokenResponse, setResetTokenResponse] = useState(null);
  const params = new URLSearchParams(location?.search);
  const userId = params.get("userId") || null;
  const token = params.get("token") || null;

  useEffect(() => {
    const checkPasswordResetToken = async () => {
      try {
        const result = await checkResetToken({
          variables: { userId: Number(userId), token },
        });

        if (result?.data?.checkPasswordResetToken?.isValid) {
          setResetTokenResponse({ success: true });
        }
        setShowForm(true);
      } catch (err) {
        const gqlError = { err };
        setShowForm(true);
        setResetTokenResponse({
          error: gqlError?.err?.message,
          messageCode: gqlError?.err?.graphQLErrors[0]?.data?.messageCode,
        });
      }
    };

    if (userId && token) {
      checkPasswordResetToken();
    } else {
      setShowForm(true);
    }
  }, []);

  const isUpdating = resetTokenResponse?.success;
  const passwordValidation = isUpdating
    ? {
        newPassword: Yup.string()
          .required(t("PASSWORD_REQUIRED"))
          .min(8, t("PASSWORD_TOO_SHORT"))
          .matches(/^(?=.*[A-Z])(?=.*[0-9])/, t("PASSWORD_INVAILD")),
      }
    : {};
  const emailValidation = isUpdating
    ? {}
    : {
        emailAddress: Yup.string()
          .email(t("EMAIL_INVALID"))
          .required(t("EMAIL_REQUIRED")),
      };

  const validationSchema = Yup.object().shape({
    ...emailValidation,
    ...passwordValidation,
  });
  return (
    <Layout metaTitle={"Log In Help"}>
      <section className="container">
        <div className="row">
          <div className="col col-12 col-lg-6 m-auto">
            <h1 className="content-header">
              <Trans i18nKey={"LOG_IN_HELP"} />
            </h1>

            <div className="card p-3 mt-3 mb-4">
              {showForm ? (
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    emailAddress: "",
                    newPassword: "",
                    tokenResponseError: resetTokenResponse,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={async (
                    values,
                    { setSubmitting, setStatus, setErrors, setFieldValue }
                  ) => {
                    setSubmitting(true);
                    try {
                      const result = isUpdating
                        ? await confirmPassword({
                            variables: {
                              newPassword: values?.newPassword,
                              userId: Number(userId),
                              token,
                            },
                          })
                        : await resetPassword({
                            variables: {
                              emailAddress: values?.emailAddress,
                            },
                          });

                      // resetTokenResponse && setResetTokenResponse(null);
                      if (result) {
                        setFieldValue("tokenResponseError", false);
                        setStatus({
                          success: true,
                          message: isUpdating
                            ? t("Your password has been reset!")
                            : t(
                                "We sent instructions to this email if it's associated with an existing account. If you don't receive an email, check your spam folder or contact customersupport for additional help."
                              ),
                        });
                      }
                      setSubmitting(false);
                    } catch (err) {
                      const gqlError = { err };
                      console.log("gqlError", gqlError);
                      setStatus({
                        error: gqlError?.err?.message,
                        messageCode:
                          gqlError?.err?.graphQLErrors[0]?.data?.messageCode,
                      });
                    }
                    setSubmitting(false);
                  }}
                >
                  {(formikProps) => {
                    const {
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      submitCount,
                      status,
                    } = formikProps;

                    const { tokenResponseError } = values;

                    return (
                      <form onSubmit={handleSubmit}>
                        {!isUpdating && (
                          <div className="form-group">
                            <label className="mb-1">
                              <Trans i18nKey={"EMAIL"} />
                            </label>
                            <input
                              className="form-control"
                              name="emailAddress"
                              type="email"
                              value={values.emailAddress}
                              onChange={handleChange}
                              //   invalid={errorEmail}
                            />
                            <div className="text-danger mt-1">
                              {touched.emailAddress && errors.emailAddress}
                            </div>
                          </div>
                        )}
                        {isUpdating && (
                          <div className="form-group">
                            <label htmlFor="newPassword">
                              <Trans i18nKey={"NEW_PASSWORD"} />
                            </label>
                            <PasswordMask
                              //   readOnly={isAdminUpdating}
                              //   required
                              maxLength={50}
                              type="password"
                              inputClassName="form-control rounded"
                              name="newPassword"
                              id="newPassword"
                              placeholder=""
                              value={values.newPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              buttonStyles={{
                                right: "4px",
                                marginTop: "-16px",
                              }}
                            />

                            <div className="small text-danger mt-1">
                              {touched.newPassword &&
                                errors.newPassword &&
                                errors.newPassword}
                            </div>
                          </div>
                        )}

                        <FormResponse
                          errors={status?.error || tokenResponseError?.error}
                          messageCode={
                            status?.messageCode ||
                            tokenResponseError?.messageCode
                          }
                          alert={
                            Object.keys(errors || {}).length > 0 &&
                            submitCount > 0
                          }
                          isVisible={
                            status?.error ||
                            status?.success ||
                            tokenResponseError?.error ||
                            (Object.keys(errors || {}).length > 0 &&
                              (tokenResponseError?.messageCode
                                ? true
                                : submitCount > 0))
                          }
                        >
                          {status?.message}
                        </FormResponse>

                        <button
                          type="submit"
                          loading={isSubmitting ? "true" : "false"}
                          className="w-100 mb-2 btn btn-secondary font-weight-bold"
                        >
                          {!isSubmitting && (
                            <>
                              {isUpdating ? (
                                <Trans i18nKey={"SAVE_PASSWORD"} />
                              ) : (
                                <Trans i18nKey={"SEND_RECOVERY_EMAIL"} />
                              )}
                            </>
                          )}
                          {isSubmitting && (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="formStatus"
                              aria-hidden="true"
                            />
                          )}
                        </button>
                      </form>
                    );
                  }}
                </Formik>
              ) : (
                <div
                  style={{ top: "0px" }}
                  className="d-flex _position-absolute justify-content-center align-items-center mt-1 w-100"
                >
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Login;
