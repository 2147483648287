import React, { Component } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";

const UserContext = React.createContext();

const ME_DATA = gql`
  query Me {
    me {
      id
      firstName
      lastName
      emailAddress
      isAdministrator
    }
  }
`;

const EXTENDED_ME_DATA = gql`
  query Me {
    me {
      id
      firstName
      lastName
      emailAddress
      # password
      taxIdentificationNumber
      state
      address
      city
      postalCode
      phoneNumber
      status
      generalExperience
      howDidYouFindUs
      detailCleanExperience
      isAdministrator
      dayOfWeekAvailability
      preferredWorkDays {
        type
      }
      preferredWorkerRoles {
        type
      }
      preferredWorkSchedule
      languagesSpoken {
        type
        fluency {
          type
        }
      }
      businessName
      preferredWorkEnvironment {
        type
        # abbreviation
      }
    }
  }
`;

const UserProvider = ({ children, pageName }) => {
  const { data: meData, loading: meQueryLoading } = useQuery(
    pageName === "profile" ? EXTENDED_ME_DATA : ME_DATA,
    { fetchPolicy: "no-cache" }
  );
  //   const { data: meData, loading: meQueryLoading } = useQuery(ME_DATA);
  return (
    // <div className="container pt-3">
    //   <div
    //     className="d-flex justify-content-center align-items-center"
    //     style={{ height: "80vh" }}
    //   >
    //     <LoadingSpinner />
    //   </div>
    // </div>

    <UserContext.Provider
      value={{
        state: {
          me: meData?.me,
          meQueryLoading,
        },
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const UserConsumer = UserContext.Consumer;

export default UserProvider;
export { UserConsumer };
