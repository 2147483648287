import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import UserProvider, { UserConsumer } from "../../contexts/User.js";
import Helmet from "react-helmet";
import { useI18next } from "gatsby-plugin-react-i18next";
import "./styles.scss";

const DefaultLayout = ({ children, pageName, footer, metaTitle, ...rest }) => {
  const { language } = useI18next();
  const isHomePage = pageName === "home";

  return (
    <div className="position-relative d-flex flex-column" id="layout-wrapper">
      <UserProvider pageName={pageName}>
        <UserConsumer>
          {({ state }) => {
            const me = state?.me;
            const meQueryLoading = state?.meQueryLoading;
            return (
              <>
                <Helmet>
                  <title>{metaTitle}</title>
                  {/* <title>
                    {" "}
                    <Trans i18nKey={metaTitle} />
                  </title> */}
                  <meta
                    name="description"
                    content="Find a house cleaning or handyman job near you in Ft.Pierce, Stuart, and Jensen Beach. Florida. No experience necessary. Part-time, full-time, you make your own hours."
                  />
                  <link rel="alternate" hreflang={language} />
                </Helmet>
                <Header
                  me={me}
                  meQueryLoading={meQueryLoading}
                  pageName={pageName}
                />
                <main
                  id="page-main-container"
                  className={`flex-grow-1  ${
                    isHomePage
                      ? "home-padding-bottom"
                      : "default-padding-bottom bg-theme pt-4 "
                  }`}
                >
                  {children}
                </main>
                {footer && <Footer />}
              </>
            );
          }}
        </UserConsumer>
      </UserProvider>
    </div>
  );
};

DefaultLayout.defaultProps = {
  footer: true,
};

export default DefaultLayout;
