import React from "react";
import Trans from "../Translate";
import "./styles.scss";

const Footer = () => {
  return (
    <footer className="page-footer position-absolute w-100 bg-white">
      <div className="container d-flex align-items-center justify-content-center h-100">
        <div>
          <span>
            <Trans i18nKey={"COPYRIGHT"} />
          </span>
          <span className="ml-1">&#169; 2021</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
