import React from "react";
import "./styles.scss";

const LoadingSpinner = ({ width, height }) => {
  return (
    <div
      className={`spinner-border loading-spinner`}
      role="status"
      style={{
        // color: "lighten(#ccc, 15%)",
        width: width || "100px",
        height: height || "100px",
      }}
    ></div>
  );
};
export default LoadingSpinner;
