import React from "react";
import {
  //   Trans,
  useTranslation,
  Link,
  useI18next,
  i18next,
} from "gatsby-plugin-react-i18next";
import WithLocation from "../WithLocation";
// import englishTranslations from "../../../locales/en/translation.json";
import Trans, { t } from "../Translate";
// import all from "gatsby-plugin-react-i18next";

import "./styles.scss";

import { gql, useMutation } from "@apollo/client";
import Cookies from "js-cookie";

const BoldAdjective = ({ language, phrase }) => {
  const words = phrase.split(" ");
  if (language != "en") {
    const lastWord = words.pop();
    return (
      <>
        {words.join(" ")} <b>{lastWord}</b>
      </>
    );
  }
  const firstWord = words.shift();

  return (
    <>
      <b>{firstWord}</b> {words.join(" ")}
    </>
  );
};
const Header = ({ pageName, location, me, meQueryLoading }) => {
  const { languages, originalPath, language, ...rest } = useI18next();

  //   const { data: meData, loading: meQueryLoading } = useQuery(gql`
  //     query Me {
  //       me {
  //         id
  //         firstName
  //         lastName
  //         emailAddress
  //         isAdministrator
  //       }
  //     }
  //   `);

  //   const { me } = meData || {};

  const closeMobileMenu = () => {
    $("body").removeClass("offcanvas-open");
    $("#navbarNavAltMarkup").removeClass("show");
    $(".navbar-toggler").addClass("collapsed");
  };
  const getLanguagePath = () => {
    /**
     * Normally we would just use the 'originalPath' variable from the i18n library, but unfortunately that variable
     * does not account for our subsequent paths for our client side routes (/admin/*). For example, the value for
     * the 'originalPath' variable when the route is '/admin' will also be '/admin', but when the route is '/admin/users'
     * the value of 'originalPath' will still only be '/admin'. To resolve this issue I introduced the location.pathname variable
     * and parse it out whenever we are within the '/admin' path.
     */
    const isAdminRoute = location.pathname.indexOf("/admin/") !== -1;
    if (isAdminRoute) {
      const splitRoute = location.pathname.split("/");
      const adminPath = splitRoute.slice(splitRoute.indexOf("admin")).join("/");
      return `/${adminPath}`;
    }
    return originalPath;
  };

  const LanguageSelect = ({ type }) => {
    const languagePath = getLanguagePath();

    return (
      <>
        <a
          className="_nav-item nav-link dropdown-toggle p-3 p-lg-0 nav-list-item _text-primary text-left"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          id={`${type}-language-dropdown`}
          href="#"
        >
          {language == "en" ? (
            <Trans i18nKey={"ENGLISH"} />
          ) : language == "es" ? (
            <Trans i18nKey={"SPANISH"} />
          ) : (
            <Trans i18nKey={"PORTUGUESE"} />
          )}
        </a>
        <div
          className="dropdown-menu py-0 py-lg-2 mt-0 dropdown-menu-right"
          aria-labelledby="language-dropdown"
        >
          {language != "en" && (
            <Link
              className="dropdown-item py-3 py-lg-1 "
              //   to={originalPath}
              to={languagePath}
              language="en"
              onClick={() => {
                closeMobileMenu();
              }}
            >
              <Trans i18nKey={"ENGLISH"} />
            </Link>
          )}

          {language != "es" && (
            <Link
              className="dropdown-item py-3 py-lg-1"
              //   to={originalPath}
              to={languagePath}
              language="es"
              onClick={() => {
                closeMobileMenu();
              }}
            >
              <Trans i18nKey={"SPANISH"} />
            </Link>
          )}
          {language != "pt" &&
            // <Link
            //   className="dropdown-item py-3 py-lg-1"
            //   //   to={originalPath}
            //   to={languagePath}
            //   language="pt"
            //   onClick={() => {
            //     closeMobileMenu();
            //   }}
            // >
            //   <Trans i18nKey={"PORTUGUESE"} />
            // </Link>
            null}
        </div>
      </>
    );
  };
  //   const getLanguage = () => {
  //     if (language === "en") {
  //       return "";
  //     }
  //     if (language) {
  //       return `/${language}`;
  //     }
  //     return "";
  //   };

  const LoggedStatusDropdown = ({ location }) => {
    const { navigate, i18n, ...rest } = useI18next();

    const [logout, { data, loading, error }] = useMutation(gql`
      mutation Logout {
        logout {
          responseCode
        }
      }
    `);

    if (me) {
      return (
        <div className="_mr-lg-5 pl-lg-5 w-100">
          <a
            className=" nav-link dropdown-toggle p-3 p-lg-0 _pr-lg-1 nav-list-item text-primary text-left mr-lg-5"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            id={`user-dropdown`}
            href="#"
          >
            {me.firstName} {me.lastName}
          </a>

          <div
            className="dropdown-menu py-0 py-lg-2"
            aria-labelledby="user-dropdown"
          >
            {me.isAdministrator === "YES" && (
              <Link
                className="dropdown-item py-3 py-lg-1 _text-primary"
                to={`/admin/users`}
                language={language}
                onClick={() => {
                  closeMobileMenu();
                }}
              >
                <Trans i18nKey={"USERS"} />
              </Link>
            )}
            <Link
              className="dropdown-item py-3 py-lg-1 _text-primary"
              //   to={`/admin/users/${me.id}`}
              to="/profile"
              language={language}
              onClick={() => {
                closeMobileMenu();
              }}
            >
              <Trans i18nKey={"PROFILE"} />
            </Link>

            <a
              //   id="logout-link"
              className="nav-item btn btn-link _nav-link dropdown-item _px-0 _p-lg-0 py-3 py-lg-1 text-nowrap d-flex align-items-center"
              //   href="#"
              state={{ prevPath: location.pathname }}
              onClick={async () => {
                try {
                  const logoutResult = await logout();
                  if (
                    logoutResult?.data?.logout?.responseCode ===
                    "LOGOUT_SUCCESS"
                  ) {
                    Cookies.remove("userToken");

                    // navigate("/");
                    window.location = "/";
                  }
                } catch (err) {
                  console.log("err", err);
                }
                closeMobileMenu();
              }}
            >
              <span className="">
                <Trans i18nKey={"LOGOUT"} />
              </span>
            </a>
          </div>
        </div>
      );
    }
    return (
      <>
        <Link
          className="nav-item nav-link  p-3 p-lg-0 mr-lg-5 nav-list-item"
          to="/register"
          onClick={() => {
            closeMobileMenu();
          }}
        >
          <Trans i18nKey={"REGISTER"} />
        </Link>

        <Link
          className="nav-item nav-link  p-3 p-lg-0 _pr-lg-1 mr-lg-5 nav-list-item"
          to="/login"
          state={{ prevPath: location.pathname }}
          onClick={() => {
            closeMobileMenu();
          }}
        >
          <Trans i18nKey="LOGIN" />
        </Link>
      </>
    );
  };

  return (
    <header id="layout-header" className={`${pageName} border-bottom-theme `}>
      <div className="d-block d-lg-none" id="mobile-nav-background"></div>
      <nav className="navbar navbar-expand-lg navbar-light h-100 _mx-0 px-4 d-flex _container justify-content-between py-0 ">
        <button
          className="navbar-toggler border-0 px-0 mr-1 collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => {
            $("body").toggleClass("offcanvas-open");
          }}
        >
          {/* <span className="navbar-toggler-icon"></span> */}
          <span className="icon-bar bar1"></span>
          <span className="icon-bar bar2"></span>
          <span className="icon-bar bar3"></span>
        </button>
        <div
          id="header-text-wrapper"
          className="flex-grow-1 d-flex ml-3 ml-lg-0 align-items-center"
        >
          <Link
            className="nav-item nav-link d-block text-dark px-0"
            to="/"
            onClick={() => {
              closeMobileMenu();
            }}
          >
            <p id="header-location-text" className={`mb-0`}>
              <Trans i18nKey={"LOCATIONS"} />
            </p>
            <h1 className="header-heading d-none d-xl-block text-nowrap">
              <BoldAdjective
                language={language}
                phrase={t("CLEANING_&_MAINTENANCE_SERVICES")}
              ></BoldAdjective>
            </h1>
            <h1 className="header-heading d-xl-none">
              <BoldAdjective
                language={language}
                phrase={t("CLEANING_SERVICES")}
              ></BoldAdjective>
            </h1>
          </Link>
        </div>

        <div
          className="collapse navbar-collapse flex-grow-0"
          id="navbarNavAltMarkup"
        >
          <div className="navbar-nav d-flex flex-column-reverse">
            <div className="d-block d-lg-flex">
              <Link
                className="nav-item nav-link mr-lg-5 nav-list-item"
                to="/shifts"
                onClick={() => {
                  closeMobileMenu();
                }}
              >
                <Trans i18nKey={"VIEW_SHIFTS"} />
              </Link>
              <Link
                className="nav-item nav-link nav-list-item pr-lg-1"
                to="/about"
                onClick={() => {
                  closeMobileMenu();
                }}
              >
                <Trans i18nKey={"ABOUT"} />
              </Link>
              <div
                id="mobile-language-select-wrapper"
                className="d-block d-lg-none"
              >
                <LanguageSelect type="mobile" />
              </div>
            </div>

            <div
              id="language-and-login-wrapper"
              className="btn-group d-block d-lg-flex align-items-center"
            >
              {!meQueryLoading && (
                <div
                  className="d-block d-lg-flex"
                  id="logged-status-dropdown-wrapper"
                >
                  <LoggedStatusDropdown location={location} />
                </div>
              )}
              <div className="d-none d-lg-flex">
                <LanguageSelect type="desktop" />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default WithLocation(Header);
